@font-face {
  font-family: "Vodafone Regular";
  src: url(//db.onlinewebfonts.com/c/923c56dc2916dfb8892c056f2f5cde5a?family=Vodafone+Rg+Regular) format("ttf");
}
html {
  height: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4 !important;
  position: relative;
  min-height: 100%;
  padding: 0;
}
.vodafone_logo{
  width: 120px;
  height:120px;
  padding:20px;
}
.image{
  background-image: url('https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1055&q=80');
  background-repeat: no-repeat;
  background-size: contain;

}
.mt-10 {
  margin-top: 10px !important;
}
.form-group{
  text-align: left;
}
.header-logo{
  width:60px;
  height:60px;
  margin-left: -2rem;
  padding: 2px;
}

@media (max-width: 501px){
  .mobile-display {
   display: block !important;
      margin-left: 10px !important;
  }
}
@media (min-width: 501px){
  .mobile-display {
    display: flex !important;
    margin-left: 10px !important;
  }
}
@media (max-width: 371px){
  .store-group {
    width: 100px !important;
  }
}
@media (min-width: 621px) {
  .header {
    text-align: right !important;
    margin-bottom: 16px !important;
    padding-bottom: 8px !important;
  }
}
@media (max-width: 621px) {
  .header {
    text-align: right ;
    margin-bottom: 16px !important;
    margin-top: 32px !important;
    padding-bottom: 8px !important;
  }
}

svg > g[class^="raphael-group-"] > text{
  display: none;
}
#contain{
  object-fit:contain;
}
#paper:hover{
  opacity:0.9;
}
/*Survey stepper*/
.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  color: #4a4d4e!important;
  font-size: 16px !important;
}
.MuiStepLabel-label.MuiStepLabel-completed  {
  color: #fc8d62 !important;
  font-size: 16px !important;
}
.MuiStepLabel-label.MuiStepLabel-active  {
  color: #fc8d62 !important;
  font-size: 16px !important;
}
.MuiTabs-scrollable{
  background-color: #F4F4F4 !important;
}
.filter-button{
  border-radius: 50px !important;
}
.excel-button{
  border-radius: 50px !important;
}
.number-icon{
  fill: #FA8072;
}
.MuiTab-root {
  min-width: 50px !important;
}
.MuiLink-underlineHover {
  color: #848484 !important;
}
.MuiList-padding {
  padding: 0 !important;
}
/*File upload button style*/
.fileContainer .chooseFileButton {
  border-radius: 30px;
  color: #FA8072 !important;
  border: 1px solid #FA8072 !important;
  background: #fff !important;
}
/*Editext style*/
.styles_Editext__view_container__2l2kB {
  font-size: 18px !important;
}
.styles_Editext__button__6H8n_ {
  border-width: 0 !important;
  background-color: #fff !important;
}
.styles_Editext__edit_button__hthOZ {
  color: #4a4d4e !important;
}
/*Black border remove for all buttons*/
button{
  outline: none!important;
}
.MuiAppBar-colorPrimary {
    background-color: #fff !important;
}
#header-3{
  cursor: pointer;
}
.question-form {
   background-color: #F4F4F4 !important;
 }
/*Header menu item style*/
.menu-item {
  font-size: 18px;
  color: #333 !important;
}
@media (max-width: 600px) {
  .menu-item {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .k-menu-link {
    padding: 2px 4px !important;
  }
}
.k-menu:not(.k-context-menu) > .k-item > .k-state-active {
  color: #fc8d62 !important;
  background-color:  #fff !important;
}
.k-menu-item:hover{
  color: #fc8d62 !important;
  background-color:  #fff !important;
}
.profile-card{
  box-shadow: rgba(0, 0, 0, 0.25) 0 54px 55px, rgba(0, 0, 0, 0.12) 0 -12px 30px, rgba(0, 0, 0, 0.12) 0 4px 6px, rgba(0, 0, 0, 0.17) 0 12px 13px, rgba(0, 0, 0, 0.09) 0 -3px 5px !important;
}
.fileContainer{
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
/*Table style*/
.table {
  color: #4a4d4e !important;
}
.MuiTableCell-root {
  text-align: center !important;
}
.MuiTableCell-head {
  font-weight: 900 !important;
}
table.table thead th, table.table td {
  font-size: 16px !important;
}
.ant-table-pagination.ant-pagination {
  display: none !important;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  background: #FFE0DD !important;
  text-align: center !important;
}
.ant-spin-container {
  background: #FFE0DD !important;
}
.ant-table-thead > tr > th {
  background: none !important;
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 900 !important;
}
.ant-table-tbody > tr > td {
  background: none !important;
  text-align: center !important;
  font-size: 16px !important;
}
tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
  background: #FFE0DD !important;
}
.login{
  height: 100vh;
 }
@media (min-width: 1200px) {
  .page-display {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
}

.sign-form {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0 14px 28px, rgba(0, 0, 0, 0.22) 0 10px 10px;
  border-radius: 24px;
}
.MuiOutlinedInput-input:-webkit-autofill {
  border-radius: 4px !important;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 4px !important;
}
.makeStyles-appointment-126{
  height: 25px !important;
  font-size: 18px !important;
  margin-top:30px;
}
.error-image{
  width:700px;
  height:500px;
}
.MuiBadge-anchorOriginTopRightRectangle {
  transform: scale(1) translate(30%, -20%) !important;
}
.MuiBadge-badge {
  font-size: 14px !important;
}
.MuiBadge-colorPrimary{
  background-color: #e60000 !important;
}
.MuiStepper-root {
  padding: 0 !important;
}
.MuiPaper-elevation0 {
  margin-left: -20px !important;
}
@media (max-width: 450px) {
  .user-greeting {
    display: none
  }
}
.MuiPaper-elevation8 {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}
.MuiPaper-elevation0 {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}
.MuiListItem-root.Mui-selected {
  color: #fc8d62 !important;
  background-color: #fff !important;
}
.MuiSelect-select:focus {
  background-color: #fff !important;
}
.MuiListItem-root.Mui-selected:hover {
  color: #fc8d62 !important;
  background-color: #FFE0DD !important;
}

.image{
  /*transform: scale3d(.5 , .5 , .5);*/
}
.MuiInput-underline:before{
  bottom: 5px!important;
}
.MuiInput-underline:after{
  bottom: 5px!important;
}
.cover{
    margin: 20px;
    width: 200px;
    height:200px;
  }
@media (max-width: 700px) {
  .cover {
    width: 100px;
    height:100px;  }
}
.controls {
  display: flex;
  align-items: center;
  margin: 10px;
}
@media (max-width: 700px) {
  .controls {
    display: block !important;
  }
}
.profile-card {
  display: flex;
  box-shadow: none !important;
}
@media (max-width: 500px) {
  .profile-card {
    display: block !important;
  }
}

@media (max-width: 621px) {
  .campaign-box {
    border: none !important;
  }
}

@media (max-width: 500px) {
  .box1 {
    display: block !important;
  }
}

@media (min-width: 500px) {
  .box1 {
    display: flex !important;
  }
}

@media (max-width: 500px) {
  .filter-field {
    width: 250px !important;
    margin: 0px 16px 16px 16px !important;
  }
}

@media (min-width: 500px) {
  .filter-field {
    width: 500px !important;
    margin: 0px 16px 16px 16px !important;
  }
}

#simple-popper{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}
@media (max-width: 500px) {
  #simple-popper {
    width: 300px !important;
    transform: translate3d(5px, 218px, 0px) !important;
  }
}

@media (min-width: 500px) {
  #simple-popper {
      width: 550px !important;
  }
}

#emoji-order{
  flex-wrap: nowrap;
}
.MuiAccordionSummary-content {
  flex-direction: column;
}
.MuiButton-label{
  display: grid
}

#emote-sets-selector{
  transform: scale3d(1.2 , 1.2 , 1.2);
  width: 25%;
  font-weight: bolder;
  font-family: "Roboto", sans-serif
}
#emote-sets-selector-option{
  width: 25%;
  font-weight: bold;
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif

}
.MuiListItem-button:hover{
  background-color: unset !important;
}
.save-question:hover{
  color:#e60000;
  background-color: unset !important;
}
.save-question-set:hover{
  color:#e60000;
  background-color: unset !important;

}
.textField-input{

  border: 2px solid #FA8072;
  height: 75px;
}


