.App {
  text-align: center;
  background: #F4F4F4;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #4a4d4e;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding:30px;
  color: white;
  position:absolute;
  width:100%;
}
